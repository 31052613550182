import React from "react";
import { Link } from "react-router-dom";
import "../../styles/index.scss";

interface MyPageCard {
  cardTitle: string;
  cardImg: string;
  cardAlt: string;
  cardName: string;
  cardLink: string;
  home: boolean;
}

const ActivityPageCard: React.FC<MyPageCard> = ({ cardTitle, cardImg, cardAlt, cardName, cardLink, home }) => {
  return (
    <div className="pageCard">
      <div className="pageCard__img">
        <img src={process.env.PUBLIC_URL + cardImg} alt={cardAlt} />
      </div>
      <div className="pageCard__text">
        <p>{cardTitle}</p>
        <h2>{cardName}</h2>
      </div>
      <div className="pageCard__link">
        <div
          className="pageCard__btn"
          onClick={() => {
            console.log(home);
          }}
        >
          <Link to={cardLink} state={{ cardTitle, cardImg, cardAlt, cardName, home }}>
            <button className="cardBtn">Детальніше</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ActivityPageCard;
