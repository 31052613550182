import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// import ".env";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyA70r516D7XTrYqNniYvA-7fUzXH83joTY",
  authDomain: "shatskiy-45ad2.firebaseapp.com",
  projectId: "shatskiy-45ad2",
  storageBucket: "shatskiy-45ad2.appspot.com",
  messagingSenderId: "207071606471",
  appId: "1:207071606471:web:7771f466626942edbc30e2",
});

const db = getFirestore(firebaseApp);

export default db;