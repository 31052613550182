import { collection, getDocs } from "firebase/firestore";
import db from "../firebase";
import {
  getAdventures,
  getBackgrounds,
  getManufacturers,
  getSecurity,
  setAdventures,
  setBackgrounds,
  setManufacturers,
  setSecurity,
} from "./sessionStorages";
import { Dispatch, SetStateAction } from "react";
import { AdventureCategory } from "interfaces/AdventureCategory";
import { TwoFrCategory } from "interfaces/TwoFrCategory";

export const fetchAdventures = async () => {
  const data = getAdventures();
  if (data) {
    return;
  } else {
    const colRef = collection(db, "adventures");
    const snapshot = await getDocs(colRef);
    let adventuresData: any = [];
    snapshot.docs.forEach((doc) => {
      adventuresData.push({ ...doc.data(), id: doc.id });
    });
    adventuresData = adventuresData.sort((a: any, b: any) => a.id - b.id);
    setAdventures(adventuresData);
    return adventuresData;
  }
};
export const fetchManufacturers = async () => {
  const data = getManufacturers();
  if (data) {
    return;
  } else {
    const colRef = collection(db, "manufacturers");
    const snapshot = await getDocs(colRef);
    let manufacturersData: any = [];
    snapshot.docs.forEach((doc) => {
      manufacturersData.push({ ...doc.data(), id: doc.id });
    });
    manufacturersData = manufacturersData.sort((a: any, b: any) => a.id - b.id);
    setManufacturers(manufacturersData);
    return manufacturersData;
  }
};
export const fetchSecurity = async () => {
  const data = getSecurity();
  if (data) {
    return;
  } else {
    const colRef = collection(db, "security");
    const snapshot = await getDocs(colRef);
    let securityData: any = [];
    snapshot.docs.forEach((doc) => {
      securityData.push({ ...doc.data(), id: doc.id });
    });
    securityData = securityData.sort((a: any, b: any) => a.id - b.id);
    setSecurity(securityData);
    return securityData;
  }
};
export const fetchBackgrounds = async () => {
  const data = getBackgrounds();
  if (data) {
    return;
  } else {
    const colRef = collection(db, "backgrounds");
    const snapshot = await getDocs(colRef);
    let backgroundsData: any = [];
    snapshot.docs.forEach((doc) => {
      backgroundsData.push({ ...doc.data(), id: doc.id });
    });
    backgroundsData = backgroundsData.sort((a: any, b: any) => a.id - b.id);
    setBackgrounds(backgroundsData);
    return backgroundsData;
  }
};

export const fastGetAdventures = async (setCategory: Dispatch<SetStateAction<AdventureCategory | any>>) => {
  const data = sessionStorage.getItem("adventures");
  if (data) {
    const adventuresData = JSON.parse(data);
    setCategory(adventuresData);
    return adventuresData;
  } else {
    const colRef = collection(db, "adventures");
    const snapshot = await getDocs(colRef);
    let adventuresData: any[] = [];
    snapshot.docs.forEach((doc) => {
      adventuresData.push({ ...doc.data(), id: doc.id });
    });
    adventuresData = adventuresData.sort((a, b) => a.id - b.id);
    sessionStorage.setItem("adventures", JSON.stringify(adventuresData));
    setCategory(adventuresData);
    return adventuresData;
  }
};

export const fastGetHeaven = async (setCategory: Dispatch<SetStateAction<TwoFrCategory | any>>) => {
  const data = sessionStorage.getItem("heaven");
  if (data) {
    const heavensData = JSON.parse(data);
    setCategory(heavensData);
    return heavensData;
  } else {
    const colRef = collection(db, "heaven");
    const snapshot = await getDocs(colRef);
    let heavensData: any[] = [];
    snapshot.docs.forEach((doc) => {
      heavensData.push({ ...doc.data(), id: doc.id });
    });
    heavensData = heavensData.sort((a, b) => a.id - b.id);
    sessionStorage.setItem("heaven", JSON.stringify(heavensData));
    setCategory(heavensData);
    return heavensData;
  }
};

export const fastGetManufacturers = async (setOtherManufacturers: Dispatch<SetStateAction<TwoFrCategory | any>>) => {
  const data = sessionStorage.getItem("manufacturers");
  if (data) {
    const manufacturersData = JSON.parse(data);
    setOtherManufacturers(manufacturersData);
    return manufacturersData;
  } else {
    const colRef = collection(db, "manufacturers");
    const snapshot = await getDocs(colRef);
    let manufacturersData: any[] = [];
    snapshot.docs.forEach((doc) => {
      manufacturersData.push({ ...doc.data(), id: doc.id });
    });
    manufacturersData = manufacturersData.sort((a, b) => a.id - b.id);
    sessionStorage.setItem("manufacturers", JSON.stringify(manufacturersData));
    setOtherManufacturers(manufacturersData);
    return manufacturersData;
  }
};

export const fastGetSecurity = async (setOtherSecurities: Dispatch<SetStateAction<TwoFrCategory | any>>) => {
  const data = sessionStorage.getItem("security");
  if (data) {
    const securityData = JSON.parse(data);
    setOtherSecurities(securityData);
    return securityData;
  } else {
    const colRef = collection(db, "security");
    const snapshot = await getDocs(colRef);
    let securityData: any[] = [];
    snapshot.docs.forEach((doc) => {
      securityData.push({ ...doc.data(), id: doc.id });
    });
    securityData = securityData.sort((a, b) => a.id - b.id);
    sessionStorage.setItem("security", JSON.stringify(securityData));
    setOtherSecurities(securityData);
    return securityData;
  }
};

export const fastGetNews = async (setOtherSecurities: Dispatch<SetStateAction<any>>) => {
  const data = sessionStorage.getItem("news");
  if (data) {
    const newsData = JSON.parse(data);
    setOtherSecurities(newsData);
    return newsData;
  } else {
    const colRef = collection(db, "news");
    const snapshot = await getDocs(colRef);
    let newsData: any[] = [];
    snapshot.docs.forEach((doc) => {
      newsData.push({ ...doc.data(), id: doc.id });
    });
    newsData = newsData.sort((a, b) => a.id - b.id);
    sessionStorage.setItem("news", JSON.stringify(newsData));
    setOtherSecurities(newsData);
    return newsData;
  }
};

export const fastGetPartnerships = async (setOtherSecurities: Dispatch<SetStateAction<any>>) => {
  const data = sessionStorage.getItem("partnership");
  if (data) {
    const partnershipData = JSON.parse(data);
    setOtherSecurities(partnershipData);
    return partnershipData;
  } else {
    const colRef = collection(db, "partnership");
    const snapshot = await getDocs(colRef);
    let partnershipData: any[] = [];
    snapshot.docs.forEach((doc) => {
      partnershipData.push({ ...doc.data(), id: doc.id });
    });
    partnershipData = partnershipData.sort((a, b) => a.id - b.id);
    sessionStorage.setItem("partnership", JSON.stringify(partnershipData));
    setOtherSecurities(partnershipData);
    return partnershipData;
  }
};

export const fastGetPlaces = async (setOtherSecurities: Dispatch<SetStateAction<any>>) => {
  const data = sessionStorage.getItem("places");
  if (data) {
    const placesData = JSON.parse(data);
    setOtherSecurities(placesData);
    return placesData;
  } else {
    const colRef = collection(db, "places");
    const snapshot = await getDocs(colRef);
    let placesData: any[] = [];
    snapshot.docs.forEach((doc) => {
      placesData.push({ ...doc.data(), id: doc.id });
    });
    placesData = placesData.sort((a, b) => a.id - b.id);
    sessionStorage.setItem("places", JSON.stringify(placesData));
    setOtherSecurities(placesData);
    return placesData;
  }
};

export const fastGetBackgrounds = async (setOtherSecurities: Dispatch<SetStateAction<any>>) => {
  const data = sessionStorage.getItem("backgrounds");
  if (data) {
    const backgroundsData = JSON.parse(data);
    setOtherSecurities(backgroundsData);
    return backgroundsData;
  } else {
    const colRef = collection(db, "backgrounds");
    const snapshot = await getDocs(colRef);
    let backgroundsData: any[] = [];
    snapshot.docs.forEach((doc) => {
      backgroundsData.push({ ...doc.data(), id: doc.id });
    });
    backgroundsData = backgroundsData.sort((a, b) => a.id - b.id);
    sessionStorage.setItem("backgrounds", JSON.stringify(backgroundsData));
    setOtherSecurities(backgroundsData);
    return backgroundsData;
  }
};
