import PageTop from "./ui/PageTop";
import "../styles/Manufacturers.scss";
import PageCard from "./ui/PageCard";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import db from "../firebase";
import { getManufacturers, setManufacturers } from "store/sessionStorages";
import { fastGetBackgrounds } from "store/loadDataFunctions";

const Manufacturers = () => {
  const [allManufacturers, setAllManufacturers] = useState<any>([]);
  const pageName = "manufacturers";
  const [allBg, setAllBg] = useState<any>(null);
  const [bg, setBG] = useState<any>(
    "https://firebasestorage.googleapis.com/v0/b/shatskiy-45ad2.appspot.com/o/Backgrounds%2FmanufacturersBG.webp?alt=media&token=37ee0bbb-8a54-4e08-a2ee-9a90d4367151"
  );

  useEffect(() => {
    const data = getManufacturers();
    if (data) {
      setAllManufacturers(data);
    } else {
      const colRef = collection(db, "manufacturers");
      getDocs(colRef)
        .then((snapshot) => {
          let lakes: any = [];
          snapshot.docs.forEach((doc) => {
            lakes.push({ ...doc.data(), id: doc.id });
          });
          lakes = lakes.sort((a: any, b: any) => a.id - b.id);
          setAllManufacturers(lakes);
          setManufacturers(lakes);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    loadBackgrounds();
  }, []);

  const loadBackgrounds = async () => {
    const items = await fastGetBackgrounds(setAllBg);
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].page === pageName) {
          setBG(items[i].img);
        }
      }
    }
  };
  return (
    <section className="manufacturers">
      <div className="manufacturers__container container">
        <PageTop pageTitle="Крафтові виробники" imgSrc={bg} imgAlt="manufacturers image" />
        <div className="manufacturers__content screen">
          <div className="manufacturers__text">
            <h1 className="title">Шацький кошик</h1>
            <p className="desc">
              Курортні Шацькі озера славляться своїми смачнючими пончиками. Вони стали гастрономічною фішкою краю і
              кожен, хто тут відпочиває, вважає невід’ємною частиною відпочинку – ласування пончиками. Не буває
              відпочинку і без риби. Знайдуть тут рибку на будь-який смак навіть найвибагливіші гурмани.
              <br />
              <br />
              Ми зібрали найпопулярніші делікатеси Шацького краю в один крафтовий кошик.
              <br />
              «Шацький крафтовий гастрономічний кошик є чудесним сувеніром та смачним подарунком-візитівкою з Шацького
              краю. За проведеним опитуванням в соціальній мережі Фейсбук було сформовано перелік продуктів, якими можна
              наповнити «Шацький кошик»:
            </p>
            <div className="manufacturers__pics">
              <ul className="desc">
                <li>1. Сушена ковбаса;</li>
                <li>2. Риба сушена на соломі;</li>
                <li>3. Світязкі пончики;</li>
                <li>4. Світязький чай;</li>
                <li>5. Варення з журавлини;</li>
                <li>6. Шацькі сири;</li>
                <li>7. Чорниці сушені;</li>
                <li>8. Мед острівянський;</li>
                <li>9. Білі сушені гриби;</li>
                <li>10. Мариновані гриби білі;</li>
                <li>11. Зелениці голядинські мариновані;</li>
                <li>12. Сушка з яблука;</li>
                <li>13. Журавлина свіжа;</li>
                <li>14. Копчені бички;</li>
                <li>15. Слива сушена.</li>
              </ul>
              <img src={bg} alt="Шацький кошик" />
            </div>
            <p className="desc">
              Але наповнення можна змінювати, в залежності від сезону та вподобань, головне додавати продукти притаманні
              нашому краю.
            </p>
          </div>
          <div className="manufacturers__list">
            {allManufacturers.map((el: any, index: any) => (
              <div key={index} className="manufacturers__card">
                <PageCard
                  cardTitle={el.pageTitle}
                  cardImg={el.pageImg}
                  cardAlt={el.pageAlt}
                  cardName={el.pageName}
                  basePath="/manufacturers"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Manufacturers;
