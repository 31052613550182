"use client";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Header.scss";

const Header: React.FC = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [logo, setLogo] = useState(process.env.PUBLIC_URL + "/logo.webp");

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 992) {
                setLogo(process.env.PUBLIC_URL + "/logo_long.webp");
            } else {
                setLogo(process.env.PUBLIC_URL + "/logo.webp");
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    return (
        <header className="header">
            <div className="header__container container">
                <Link to={"/"} className="header__logo">
                    <img src={logo} alt="Logo" />
                </Link>
                <nav className="header__nav">
                    <ul className="header__ul">
                        <li className="header__li">
                            <Link to={"/lakes"} className={location.pathname === '/lakes' ? 'active' : ''}>ПРИРОДА 30 ОЗЕР</Link>
                        </li>
                        <li className="header__li">
                            <Link to={"/heaven"} className={location.pathname === '/heaven' ? 'active' : ''}>РАЙ БУВ ТУТ</Link>
                        </li>
                        <li className="header__li">
                            <Link to={"/adventures"} className={location.pathname === '/adventures' ? 'active' : ''}>МІСЦЯ І ПРИГОДИ</Link>
                        </li>
                        <li className="header__li">
                            <Link to={"/manufacturers"} className={location.pathname === '/manufacturers' ? 'active' : ''}>КРАФТОВІ ВИРОБНИКИ</Link>
                        </li>
                        <li className="header__li">
                            <Link to={"/partnership"} className={location.pathname === '/partnership' ? 'active' : ''}>ПАРТНЕРСТВО</Link>
                        </li>
                        <li className="header__li">
                            <Link to={"/security"} className={location.pathname === '/security' ? 'active' : ''}>БЕЗПЕКА</Link>
                        </li>
                        <li className="header__li">
                            <Link to={"/places"} className={location.pathname === '/places' ? 'active' : ''}>УНІКАЛЬНІ МІСЦЯ ДЛЯ СЕЛФІ</Link>
                        </li>
                    </ul>
                </nav>
                <div className={`header__burger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <span className="burger-icon"></span>
                </div>
            </div>
            <div className={`header__menu ${menuOpen ? 'open' : ''}`}>
                <div className="header__menu-link" onClick={toggleMenu}>
                    <nav className="header__menu-nav">
                        <ul className="header__menu-ul">
                            <li className="header__menu-li">
                                <Link to={"/lakes"} className={location.pathname === '/lakes' ? 'active' : ''}>ПРИРОДА 30 ОЗЕР</Link>
                            </li>
                            <li className="header__menu-li">
                                <Link to={"/heaven"} className={location.pathname === '/heaven' ? 'active' : ''}>РАЙ БУВ ТУТ</Link>
                            </li>
                            <li className="header__menu-li">
                                <Link to={"/adventures"} className={location.pathname === '/adventures' ? 'active' : ''}>МІСЦЯ І ПРИГОДИ</Link>
                            </li>
                            <li className="header__menu-li">
                                <Link to={"/manufacturers"} className={location.pathname === '/manufacturers' ? 'active' : ''}>КРАФТОВІ ВИРОБНИКИ</Link>
                            </li>
                            <li className="header__menu-li">
                                <Link to={"/partnership"} className={location.pathname === '/partnership' ? 'active' : ''}>ПАРТНЕРСТВО</Link>
                            </li>
                            <li className="header__menu-li">
                                <Link to={"/security"} className={location.pathname === '/security' ? 'active' : ''}>БЕЗПЕКА</Link>
                            </li>
                            <li className="header__menu-li">
                                <Link to={"/places"} className={location.pathname === '/places' ? 'active' : ''}>УНІКАЛЬНІ МІСЦЯ ДЛЯ СЕЛФІ</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;