import React from 'react';
import "../../styles/index.scss";

interface MyPageCard {
    cardTitle: string;
    cardImg: string;
    cardAlt: string;
    mapLink: string;
}

const SelfiePageCard: React.FC<MyPageCard> = ({
    cardTitle, 
    cardImg, 
    cardAlt, 
    mapLink
}) => {

  return (
    <div className='pageCard'>
        <div className="pageCard__img">
            <img 
                src={process.env.PUBLIC_URL + cardImg} 
                alt={cardAlt} 
            />
        </div>
        <div className="pageCard__text">
            <h2>{cardTitle}</h2>
        </div>
        <div className="pageCard__link">
            <div className="pageCard__btn">
                <a href={mapLink} target="_blank" rel="noopener noreferrer">
                    <button className='cardBtn'>На мапі</button>
                </a>
            </div>
        </div>
    </div>
  )
}

export default SelfiePageCard;