import PageTop from "./ui/PageTop";
import SelfiePageCard from "./ui/SelfiePageCard";
import "../styles/Places.scss";
import { useEffect, useState } from "react";
import { fastGetBackgrounds, fastGetPlaces } from "store/loadDataFunctions";

const Places = () => {
  const [places, setPlaces] = useState<any>(null);
  const pageName = "places";
  const [allBg, setAllBg] = useState<any>(null);
  const [bg, setBG] = useState<any>(
    "https://firebasestorage.googleapis.com/v0/b/shatskiy-45ad2.appspot.com/o/Backgrounds%2Fsunset.webp?alt=media&token=1c062b35-2b11-4648-85aa-911181c751f8"
  );

  useEffect(() => {
    const loadPartnerships = async () => {
      const data = await fastGetPlaces(setPlaces);
      if (data) {
        return;
      }
    };

    loadPartnerships();
    loadBackgrounds();
  }, []);

  const loadBackgrounds = async () => {
    const items = await fastGetBackgrounds(setAllBg);
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].page === pageName) {
          setBG(items[i].img);
        }
      }
    }
  };
  return (
    <section className="places">
      <div className="places__container container">
        <PageTop pageTitle="Унікальні місця для селфі" imgSrc={bg} imgAlt="places image" />
        <div className="places__list screen">
          {places &&
            places.map((el: any, index: any) => (
              <div className="places__card" key={index}>
                <SelfiePageCard cardTitle={el.title} cardImg={el.img} cardAlt={el.title} mapLink={el.mapLink} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Places;
