import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Route, Routes } from "react-router-dom";
import "./styles/App.scss";
import { Provider } from "react-redux";
import store from "./store/store";
import Lakes from "./components/Lakes";
import Footer from "components/Footer";
import Home from "components/Home";
import Heaven from "components/Heaven";
import Adventures from "components/Adventures";
import Manufacturers from "components/Manufacturers";
import Partnership from "components/Partnership";
import Security from "components/Security";
import Places from "components/Places";
import LakesDetail from "components/LakesDetail";
import TwoFrDetail from "components/TwoFrDetail";
import ScrollToTop from "components/ui/ScrollToTop";
import AdventuresDetail from "components/AdventuresDetail";
import ManufacturersDetail from "components/ManufacturersDetail";
import NewsDetail from "components/NewsDetail";
import SecurityDetail from "components/SecurityDetail";
import { fetchAdventures, fetchBackgrounds, fetchManufacturers, fetchSecurity } from "store/loadDataFunctions";
import PartnershipDetail from "components/PartnershipDetail";

const App: React.FC = () => {
  const [isLoad, setIsLoad] = useState(true);
  const [isLoadBody, setIsLoadBody] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoad(false);
    }, 3500);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoadBody(false);
      fetchAdventures();
      fetchManufacturers();
      fetchSecurity();
      fetchBackgrounds();
    }, 1000);
  }, []);
  return (
    <div className="App">
      {isLoad === true ? (
        <div className="LoaderBody">
          <div className="loader">
            <span>завантаження...</span>
            <span>завантаження...</span>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isLoadBody === true ? (
        <></>
      ) : (
        <Provider store={store}>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/news/:cardName" element={<NewsDetail />} />
            <Route path="/lakes" element={<Lakes />} />
            <Route path="/lakes/:cardName" element={<LakesDetail />} />
            <Route path="/heaven" element={<Heaven />} />
            <Route path="/heaven/:categoryName" element={<TwoFrDetail />} />
            <Route path="/adventures" element={<Adventures />} />
            <Route path="/adventures/:categoryName" element={<AdventuresDetail />} />
            <Route path="/manufacturers" element={<Manufacturers />} />
            <Route path="/manufacturers/:cardName" element={<ManufacturersDetail />} />
            <Route path="/partnership" element={<Partnership />} />
            <Route path="/partnership/:cardName" element={<PartnershipDetail />} />
            <Route path="/security" element={<Security />} />
            <Route path="/security/:cardName" element={<SecurityDetail />} />
            <Route path="/places" element={<Places />} />
          </Routes>
          <Footer />
        </Provider>
      )}
    </div>
  );
};

export default App;
