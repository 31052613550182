"use client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, FreeMode, Navigation } from "swiper/modules";
import { collection, getDocs } from "firebase/firestore";
import db from "../firebase";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import PageCard from "./ui/PageCard";
import ActivityPageCard from "./ui/ActivityPageCard";
import { getActivities } from "store/sessionStorages";
import { fastGetBackgrounds, fastGetNews } from "store/loadDataFunctions";

const Home = () => {
  const [activities, setActivities] = useState<any>(null);
  const [news, setNews] = useState<any>(null);
  const pageName = "home";
  const [allBg, setAllBg] = useState<any>(null);
  const [bg, setBG] = useState<any>(
    "https://firebasestorage.googleapis.com/v0/b/shatskiy-45ad2.appspot.com/o/Backgrounds%2Fsunset.webp?alt=media&token=1c062b35-2b11-4648-85aa-911181c751f8"
  );

  useEffect(() => {
    const data = getActivities();
    if (data) {
      setActivities(data);
    } else {
      const colRef = collection(db, "activity_HOME");
      getDocs(colRef)
        .then((snapshot) => {
          let allActivity: any = [];
          snapshot.docs.forEach((doc) => {
            allActivity.push({ ...doc.data() });
          });
          setActivities(allActivity);
          setActivities(allActivity);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }

    loadNews();
    loadBackgrounds();
  }, []);

  const loadBackgrounds = async () => {
    const items = await fastGetBackgrounds(setAllBg);
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].page === pageName) {
          setBG(items[i].img);
        }
      }
    }
  };

  const loadNews = async () => {
    const data = await fastGetNews(setNews);
    if (data) {
      return;
    }
  };

  return (
    <section className="home">
      <div className="home__container container">
        <div className="home__top" style={{ backgroundImage: `url(${bg})` }}>
          <div className="home__top-content screen">
            <div className="empty"></div>
            <div className="home__txt-container">
              <h1>
                Шацький край -
                <br />
                природа 30 озер
              </h1>
              <Link to={"/lakes"}>
                <button className="mainBtn">Дізнатись більше</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="home__content screen">
          <div className="home__activity">
            <h3 className="title">Активності</h3>
            <div className="activity__list">
              {activities &&
                activities.map((el: any, index: any) => (
                  <ActivityPageCard
                    key={index}
                    cardTitle={el.title}
                    cardImg={el.img}
                    cardAlt={el.alt}
                    cardName={el.name}
                    cardLink={el.link}
                    home={el.pageID}
                  />
                ))}
            </div>
          </div>
        </div>
        <section className="brand">
          <div className="brand__container screen">
            <div className="brand__text">
              <h2 className="title">
                Бренд
                <br />
                Шацького краю
              </h2>
              <p>Обирай наш мерч та залишай незабутні спогади на довгі роки</p>
              <Link to={"/partnership/Брендбук%20Шацький%20край"}>
                <button className="mainBtn _small">Про використання бренду</button>
              </Link>
            </div>
            <div className="brand__slider">
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={40}
                autoplay={{
                  delay: 10000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                autoHeight={false}
                freeMode={true}
                grabCursor={true}
                resistanceRatio={0}
                loop={true}
                className="brandSwiper"
              >
                {brandCards.map((el, index) => (
                  <SwiperSlide key={index} className="brandSwiper__slides">
                    <img src={process.env.PUBLIC_URL + el.img} alt={el.alt} />
                    <div className="brandSwiper__txt">
                      <h3>{el.title}</h3>
                      <h4>{el.name}</h4>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
        <section className="news screen">
          <div className="news__txt">
            <h3 className="title">Новини краю</h3>
            <p className="desc">Дізнавайся першим про наші події</p>
          </div>
          <div className="news__content">
            <Swiper
              spaceBetween={40}
              freeMode={true}
              grabCursor={true}
              // slidesPerView={3}
              resistanceRatio={0}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                600: {
                  slidesPerView: 2,
                },
                951: {
                  slidesPerView: 3,
                },
              }}
              modules={[Autoplay]}
              className="lake__swiper-other"
            >
              {news &&
                news.map((el: any, index: any) => (
                  <SwiperSlide key={index} className="lake__swiper-other-slides">
                    <PageCard
                      cardTitle={el.title}
                      cardImg={el.img}
                      cardAlt={el.alt}
                      cardName={el.name}
                      basePath="/news"
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </section>
        <section className="video-content screen">
          <div className="video-content__txt">
            <h3 className="title">Відео краю</h3>
            <p className="desc">Моменти на все життя</p>
          </div>
          <div className="video-content__video">
            <Swiper
              spaceBetween={40}
              navigation={true}
              freeMode={true}
              grabCursor={true}
              // slidesPerView={3}
              resistanceRatio={0}
              loop={true}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                600: {
                  slidesPerView: 2,
                },
                951: {
                  slidesPerView: 3,
                },
              }}
              modules={[FreeMode, Navigation]}
              className="video-content__swiper"
            >
              {videoData.map((el, index) => (
                <SwiperSlide className="video-content__slide-video" key={index}>
                  <div className="video-content__list">
                    <video controls width="100%">
                      <source src={process.env.PUBLIC_URL + el.video} type="video/mp4" />
                      Ваш браузер не підтримує відтворення відео.
                    </video>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
        <section className="pictures">
          <div className="pictures__txt screen">
            <p className="desc">Ділись спогадами з нами</p>
            <h3 className="title">#Фотогалерея</h3>
          </div>
          <div className="pictures__gallery">
            <div className="angry-grid">
              <div id="item-0"></div>
              <div id="item-1"></div>
              <div id="item-2"></div>
              <div id="item-3"></div>
              <div id="item-4"></div>
              <div id="item-5"></div>
              <div id="item-6"></div>
              <div id="item-7"></div>
              <div id="item-8"></div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Home;

const brandCards = [
  {
    id: 0,
    img: "/brand/1.webp",
    alt: "Футболка",
    title: "01 - Топ продажів",
    name: "Футболка",
  },
  {
    id: 1,
    img: "/brand/2.webp",
    alt: "Торбинка",
    title: "02 - Еко вибір",
    name: "Торбинка",
  },
  {
    id: 2,
    img: "/brand/3.webp",
    alt: "Пакет",
    title: "03 - Для подарунків",
    name: "Пакет",
  },
  {
    id: 3,
    img: "/brand/4.webp",
    alt: "Нотатник",
    title: "04 - Друзям",
    name: "Нотатник",
  },
];

const videoData = [
  {
    id: 0,
    video: "/video/1.webm",
  },
  {
    id: 1,
    video: "/video/2.webm",
  },
  {
    id: 2,
    video: "/video/3.webm",
  },
  {
    id: 3,
    video: "/video/1.webm",
  },
  {
    id: 4,
    video: "/video/2.webm",
  },
  {
    id: 5,
    video: "/video/3.webm",
  },
];
