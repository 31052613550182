export const getLakesData = () => {
  const lakes = sessionStorage.getItem("lakes");
  return lakes ? JSON.parse(lakes) : null;
};

export const setLakesData = (data: any): void => {
  sessionStorage.setItem("lakes", JSON.stringify(data));
};

export const getHeavenTypes = () => {
  const heaven_types = sessionStorage.getItem("heaven_types");
  return heaven_types ? JSON.parse(heaven_types) : null;
};

export const setHeavenTypes = (data: any): void => {
  sessionStorage.setItem("heaven_types", JSON.stringify(data));
};

export const getAdventures = () => {
  const adventure = sessionStorage.getItem("adventure");
  return adventure ? JSON.parse(adventure) : null;
};

export const setAdventures = (data: any): void => {
  sessionStorage.setItem("adventure", JSON.stringify(data));
};

export const getManufacturers = () => {
  const manufacturers = sessionStorage.getItem("manufacturers");
  return manufacturers ? JSON.parse(manufacturers) : null;
};

export const setManufacturers = (data: any): void => {
  sessionStorage.setItem("manufacturers", JSON.stringify(data));
};

export const getSecurity = () => {
  const manufacturers = sessionStorage.getItem("security");
  return manufacturers ? JSON.parse(manufacturers) : null;
};

export const setSecurity = (data: any): void => {
  sessionStorage.setItem("security", JSON.stringify(data));
};

export const getActivities = () => {
  const manufacturers = sessionStorage.getItem("activity");
  return manufacturers ? JSON.parse(manufacturers) : null;
};

export const setActivities = (data: any): void => {
  sessionStorage.setItem("activity", JSON.stringify(data));
};

export const getPartnership = () => {
  const manufacturers = sessionStorage.getItem("partnership");
  return manufacturers ? JSON.parse(manufacturers) : null;
};

export const setPartnership = (data: any): void => {
  sessionStorage.setItem("partnership", JSON.stringify(data));
};

export const getBackgrounds = () => {
  const manufacturers = sessionStorage.getItem("backgrounds");
  return manufacturers ? JSON.parse(manufacturers) : null;
};

export const setBackgrounds = (data: any): void => {
  sessionStorage.setItem("backgrounds", JSON.stringify(data));
};
