"use client";
import ArrowToRightIcon from "assets/ArrowToRightIcon";
import "../styles/LakesDetail.scss";
import "../styles/TwoFrDetail.scss";
import React, { useRef, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { collection, getDocs } from "firebase/firestore";
import { getLakesData } from "store/sessionStorages";
import PageCard from "./ui/PageCard";
import db from "../firebase";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

const LakesDetail: React.FC = () => {
  const { cardName } = useParams<{ cardName: string }>();
  const [lake, setLake] = useState<any>(null);
  const [otherLakes, setOtherLakes] = useState<any>(null);

  useEffect(() => {
    const data = getLakesData();
    if (data) {
      setOtherLakes(data);
      for (let i = 0; i < data.length; i++) {
        if (data[i].name === cardName) {
          setLake(data[i]);
          return;
        }
      }
    } else {
      const colRef = collection(db, "lakes");
      getDocs(colRef)
        .then((snapshot) => {
          let allLakes: any = [];
          snapshot.docs.forEach((doc) => {
            allLakes.push({ ...doc.data() });
            if (doc.data().name === cardName) {
              setLake(doc.data());
            }
          });
          setOtherLakes(allLakes);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [cardName]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }, [cardName]);

  if (!lake) {
    return <div>Інформацію не знайдено</div>;
  }

  const hasLakeFacts = lake.area || lake.medDepth || lake.maxDepth || lake.shoreline || lake.length || lake.width;

  return (
    <section className="lake" ref={containerRef}>
      <div className="lake-breadcrumbs">
        <div className="lake-breadcrumbs__links screen">
          <Link to={"/"} className="lake-breadcrumbs__link">
            Головна
          </Link>
          <ArrowToRightIcon />
          <Link to={"/lakes"} className="lake-breadcrumbs__link">
            Природа 30 озер
          </Link>
          <ArrowToRightIcon />
          <h4>
            {lake.title} {lake.name}
          </h4>
        </div>
      </div>
      <div className="lake__container screen">
        <div className="lake__details">
          <div className="lake__info">
            <div className="lake__short">
              <div className="lake__img">
                <img src={lake.img} alt={lake.alt} />
              </div>
              <div className="lake__facts-section">
                <h1 className="title">
                  {lake.title} {cardName}
                </h1>
                {hasLakeFacts && (
                  <div className="lake__short-facts">
                    <h4>Швидкі факти:</h4>
                    <div className="lake__facts-list">
                      <div className="lake__facts-list-fact">
                        {lake.area && <h3>Площа</h3>}
                        {lake.medDepth && <h3>Сер. глибина</h3>}
                        {lake.maxDepth && <h3>Макс. глибина</h3>}
                        {lake.length && <h3>Довжина</h3>}
                        {lake.width && <h3>Ширина</h3>}
                        {lake.shoreline && <h3>Довжина берегової лінії</h3>}
                      </div>
                      <div className="lake__facts-list-numbs">
                        {lake.area && <p>{lake.area} га</p>}
                        {lake.medDepth && <p>{lake.medDepth} м</p>}
                        {lake.maxDepth && <p>{lake.maxDepth} м</p>}
                        {lake.length && <p>{lake.length} м</p>}
                        {lake.width && <p>{lake.width} м</p>}
                        {lake.shoreline && <p>{lake.shoreline} м</p>}
                      </div>
                    </div>
                  </div>
                )}
                <div className="lake__desc">
                  <p className="desc">{lake.descShort}</p>
                </div>
                {lake.mapLink && (
                  <div className="lake__map">
                    <a href={lake.mapLink} target="_blank" rel="noopener noreferrer">
                      <button className="mapBtn">На мапі</button>
                    </a>
                  </div>
                )}
              </div>
            </div>
            <p className="desc">{lake.descFull}</p>
          </div>
          <div className="lake__photos">
            <Swiper
              spaceBetween={40}
              autoHeight={true}
              freeMode={true}
              grabCursor={true}
              slidesPerView={1.5}
              resistanceRatio={0}
              loop={true}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="lake__swiper-first"
            >
              {lake.slides?.map((slide: any, index: any) => (
                <SwiperSlide key={index} className="lake__swiper-first-slides">
                  <img src={slide} alt="lake photos" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* {lake.otherData?.map((el: any, index: any) => (
            <div key={index} className="lake__otherData">
              {el.title && <h3 className="title">{el.title}</h3>}
              {el.desc && (
                <p className="desc">
                  {el.desc}
                  <br />
                  <br />
                  <br />
                </p>
              )}
              {el.mapLink && (
                <a href={el.mapLink} target="_blank" rel="noopener noreferrer">
                  <button className="mapBtn">На мапі</button>
                  <br />
                  <br />
                  <br />
                </a>
              )}
              {el.slides && el.slides.length > 0 && (
                <div className="two-fr-detail__photos">
                  <Swiper
                    spaceBetween={40}
                    autoHeight={true}
                    freeMode={true}
                    grabCursor={true}
                    slidesPerView={"auto"}
                    resistanceRatio={0}
                    loop={true}
                    autoplay={{
                      delay: 10000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation]}
                    className="two-fr-detail__swiper"
                  >
                    {el.slides.map((slide: any, index: any) => (
                      <SwiperSlide key={index} className="two-fr-detail__slides">
                        <img src={process.env.PUBLIC_URL + slide} alt="photos" />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
            </div>
          ))} */}
          {lake.otherData?.map((el: any, index: any) => (
            <div key={index} className="lake__otherData">
              {el.title && <h3 className="title">{el.title}</h3>}
              {el.desc && (
                <p className="desc">
                  {el.desc}
                  <br />
                  <br />
                  <br />
                </p>
              )}
              {el.mapLink && (
                <a href={el.mapLink} target="_blank" rel="noopener noreferrer">
                  <button className="mapBtn">На мапі</button>
                  <br />
                  <br />
                  <br />
                </a>
              )}
              {Array.isArray(el.slides) && el.slides.length > 0 && (
                <div className="two-fr-detail__photos">
                  <Swiper
                    spaceBetween={40}
                    autoHeight={true}
                    freeMode={true}
                    grabCursor={true}
                    slidesPerView={"auto"}
                    resistanceRatio={0}
                    loop={true}
                    autoplay={{
                      delay: 10000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation]}
                    className="two-fr-detail__swiper"
                  >
                    {el.slides.map((slide: any, index: any) => (
                      <SwiperSlide key={index} className="two-fr-detail__slides">
                        <img src={slide} alt="photos" />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="lake__other-lakes">
          <h1 className="title">Схожі сторінки</h1>
          <Swiper
            spaceBetween={40}
            freeMode={true}
            grabCursor={true}
            slidesPerView={3}
            resistanceRatio={0}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="lake__swiper-other"
          >
            {otherLakes.map((otherLake: any, index: any) => (
              <SwiperSlide key={index} className="lake__swiper-other-slides">
                <PageCard
                  cardTitle={otherLake.title}
                  cardImg={otherLake.img}
                  cardAlt={otherLake.alt}
                  cardName={otherLake.name}
                  basePath="/lakes"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default LakesDetail;
