"use client";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ChildPageTop from "./ui/ChildPageTop";
import "../styles/TwoFrDetail.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { collection, getDocs } from "firebase/firestore";
import db from "../firebase";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { TwoFrCategory } from "interfaces/TwoFrCategory";
import { TwoFrItem } from "interfaces/TwoFrItem";
import { fastGetHeaven } from "store/loadDataFunctions";

const TwoFrDetail: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { categoryName } = useParams<{ categoryName: string }>();
  const [category, setCategory] = useState<TwoFrCategory>();
  const [categoryItems, setCategoryItems] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<TwoFrItem | null>(null);

  useEffect(() => {
    const loadAdventures = async () => {
      const data = await fastGetHeaven(setCategory);
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].pageName === categoryName) {
            setCategory(data[i]);
            const colRefHeaven = collection(db, data[i].type);
            getDocs(colRefHeaven)
              .then((snapshot) => {
                let heaven_Data: any = [];
                snapshot.docs.forEach((doc) => {
                  heaven_Data.push({ ...doc.data(), id: doc.id });
                });
                setSelectedItem(heaven_Data[0]);
                setCategoryItems(heaven_Data);
              })
              .catch((err) => {
                console.log(err.message);
              });
          }
        }
      }
    };

    loadAdventures();
  }, [categoryName]);

  useEffect(() => {}, [category]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!category) {
    return <div>Категорія не знайдена</div>;
  }

  return (
    <section className="two-fr">
      {category && (
        <ChildPageTop
          pageTitle={category.pageName}
          imgSrc={category.pageImg}
          imgAlt={category.pageAlt}
          prevPage={category.motherPage}
          prevPageLink={category.motherPageLink}
        />
      )}
      <div className="two-fr-detail screen">
        {windowWidth > 768 ? (
          <div className="two-fr-detail__list">
            {categoryItems &&
              categoryItems.map((item: any) => (
                <div
                  key={item.id}
                  onClick={() => setSelectedItem(item)}
                  className={`two-fr-item ${selectedItem?.id === item.id ? "active" : ""}`}
                >
                  <div className="item-content">
                    <p>{item.name}</p>
                  </div>
                  <div className="item-background" style={{ backgroundImage: `url(${item.img})` }} />
                </div>
              ))}
          </div>
        ) : (
          <div className="two-fr-detail__swiper-list">
            <Swiper
              spaceBetween={20}
              freeMode={true}
              grabCursor={true}
              slidesPerView={"auto"}
              resistanceRatio={0}
              loop={false}
              modules={[Autoplay]}
              className="swiper-list__swiper-top-list"
            >
              {categoryItems &&
                categoryItems.map((item: any) => (
                  <SwiperSlide key={item.id} onClick={() => setSelectedItem(item)}>
                    <div className={`two-fr-item ${selectedItem?.id === item.id ? "active" : ""}`}>
                      <div className="item-content">
                        <p>{item.name}</p>
                      </div>
                      <div className="item-background" style={{ backgroundImage: `url(${item.img})` }} />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        )}
        <div className="two-fr-detail__description">
          {selectedItem && (
            <>
              <h2 className="title">{selectedItem.name}</h2>
              <div className="two-fr-detail__paragraph">
                <p className="desc">{selectedItem.description}</p>
              </div>
              {selectedItem.mapLink && (
                <div className="two-fr-detail__map">
                  <a href={selectedItem.mapLink} target="_blank" rel="noopener noreferrer">
                    <button className="mapBtn">На мапі</button>
                  </a>
                </div>
              )}
              <div className="two-fr-detail__photos">
                {selectedItem.slides.length > 0 ? (
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={"auto"}
                    autoHeight={true}
                    freeMode={true}
                    grabCursor={true}
                    resistanceRatio={0}
                    navigation={true}
                    autoplay={{
                      delay: 10000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation]}
                    className="two-fr-detail__swiper"
                  >
                    {selectedItem.slides.map((slide, index) => (
                      <SwiperSlide key={index} className="two-fr-detail__slides">
                        <img src={process.env.PUBLIC_URL + slide} alt={`slide-${index}`} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  <p className="desc">Перепрошуємо, наразі немає фотографій</p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default TwoFrDetail;
