import React from 'react'

const TelegramIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1367_2075)">
            <path d="M12 24C5.383 24 0 18.617 0 12C0 5.383 5.383 0 12 0C18.617 0 24 5.383 24 12C24 18.617 18.617 24 12 24ZM12 1C5.935 1 1 5.935 1 12C1 18.065 5.935 23 12 23C18.065 23 23 18.065 23 12C23 5.935 18.065 1 12 1Z" fill="#1919DD"/>
            <path d="M9.49997 18.0001C9.43597 18.0001 9.36997 17.9881 9.30897 17.9621C9.12197 17.8851 8.99997 17.7021 8.99997 17.5001V14.5001C8.99997 14.3671 9.05297 14.2401 9.14597 14.1461L11.506 11.7861L7.74697 13.9331C7.58597 14.0251 7.38797 14.0201 7.22997 13.9201L4.22997 12.0071C4.07097 11.9061 3.98297 11.7241 4.00097 11.5371C4.01997 11.3501 4.14197 11.1891 4.31597 11.1201L17.316 6.03308C17.487 5.96908 17.676 5.99808 17.816 6.11308C17.957 6.22808 18.023 6.41008 17.991 6.58908L15.991 17.5891C15.963 17.7441 15.863 17.8771 15.722 17.9471C15.581 18.0171 15.415 18.0171 15.275 17.9471L11.597 16.1081L9.85197 17.8531C9.75797 17.9491 9.62997 18.0001 9.49997 18.0001ZM11.5 15.0001C11.576 15.0001 11.652 15.0171 11.724 15.0531L15.127 16.7551L16.848 7.29208L5.59797 11.6941L7.51497 12.9161L14.252 9.06608C14.47 8.94308 14.746 8.99808 14.898 9.19608C15.05 9.39508 15.031 9.67608 14.854 9.85308L9.99997 14.7071V16.2931L11.146 15.1471C11.242 15.0501 11.37 15.0001 11.5 15.0001Z" fill="#1919DD"/>
        </g>
        <defs>
            <clipPath id="clip0_1367_2075">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  )
}

export default TelegramIcon;