"use client";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ChildPageTop from "./ui/ChildPageTop";
import "../styles/TwoFrDetail.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { collection, getDocs } from "firebase/firestore";
import db from "../firebase";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import InstaIcon from "assets/InstaIcon";
import FacebookIcon from "assets/FacebookIcon";
import TiktokIcon from "assets/TiktokIcon";
import ReactPaginate from "react-paginate";
import { fastGetAdventures } from "store/loadDataFunctions";
import { AdventureCategory } from "interfaces/AdventureCategory";
import { AdventureItem } from "interfaces/AdventureItem";
import { useLocation } from "react-router-dom";

const AdventuresDetail: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { categoryName } = useParams<{ categoryName: string }>();
  const [category, setCategory] = useState<AdventureCategory>();
  const [categoryItems, setCategoryItems] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<AdventureItem | null>(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const [currentCategories, setCurrentCategories] = useState([]);
  const location = useLocation();
  const { home } = location.state || {};

  const changePageLength = () => {
    const width = window.innerWidth;
    if (width >= 1200) {
      setItemsPerPage(7);
    } else if (width < 1200 && width >= 768) {
      setItemsPerPage(5);
    }
  };

  useEffect(() => {
    console.log(home);
    const loadAdventures = async () => {
      const data = await fastGetAdventures(setCategory);
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].pageName === categoryName) {
            setCategory(data[i]);
            const colRefAdv = collection(db, data[i].type);
            getDocs(colRefAdv)
              .then((snapshot) => {
                let adv_Data: any = [];
                snapshot.docs.forEach((doc) => {
                  adv_Data.push({ ...doc.data(), id: doc.id });
                });
                if (home) {
                  setSelectedItem(adv_Data[home]);
                } else {
                  setSelectedItem(adv_Data[0]);
                }
                setCategoryItems(adv_Data);
              })
              .catch((err) => {
                console.log(err.message);
              });
          }
        }
      }
    };

    loadAdventures();
  }, [categoryName, home]);

  useEffect(() => {
    if (categoryItems) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentCategories(categoryItems.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(categoryItems.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, categoryItems]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % categoryItems.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      changePageLength();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!category) {
    return <div>Категорія не знайдена</div>;
  }

  return (
    <section className="two-fr">
      <ChildPageTop
        pageTitle={category.pageName}
        imgSrc={category.pageImg}
        imgAlt={category.pageAlt}
        prevPage={category.motherPage}
        prevPageLink={category.motherPageLink}
      />
      <div className="two-fr-detail screen">
        {windowWidth > 768 ? (
          <div className="two-fr-detail__list">
            {currentCategories &&
              currentCategories.map((item: any) => (
                <div
                  key={item.id}
                  onClick={() => setSelectedItem(item)}
                  className={`two-fr-item ${selectedItem?.id === item.id ? "active" : ""}`}
                >
                  <div className="item-content">
                    <p>{item.name}</p>
                  </div>
                  <div className="item-background" style={{ backgroundImage: `url(${item.img})` }} />
                </div>
              ))}
            {pageCount < 2 ? (
              <></>
            ) : (
              <ReactPaginate
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={-1}
                marginPagesDisplayed={-1}
                pageCount={pageCount}
                previousLabel="<"
                breakLabel=""
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="GelleryPhotosPagination"
                nextLinkClassName="prevsPug"
                previousLinkClassName="prevsPug"
                renderOnZeroPageCount={null}
              />
            )}
          </div>
        ) : (
          <div className="two-fr-detail__swiper-list">
            <Swiper
              spaceBetween={40}
              freeMode={true}
              grabCursor={true}
              slidesPerView={2}
              resistanceRatio={0}
              loop={false}
              modules={[Autoplay]}
              className="swiper-list__swiper-top-list"
            >
              {categoryItems &&
                categoryItems.map((item: any) => (
                  <SwiperSlide key={item.id} onClick={() => setSelectedItem(item)}>
                    <div className={`two-fr-item ${selectedItem?.id === item.id ? "active" : ""}`}>
                      <div className="item-content">
                        <p>{item.name}</p>
                      </div>
                      <div className="item-background" style={{ backgroundImage: `url(${item.img})` }} />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        )}
        <div className="two-fr-detail__description">
          {selectedItem && (
            <>
              <h2 className="title">{selectedItem.name}</h2>
              <div className="two-fr-detail__shorts">
                {selectedItem.route && (
                  <h3>
                    Маршрут: <span className="desc">{selectedItem.route}</span>
                  </h3>
                )}
                {selectedItem.duration && (
                  <h3>
                    Тривалість: <span className="desc">{selectedItem.duration}</span>
                  </h3>
                )}
                {selectedItem.length && (
                  <h3>
                    Протяжність: <span className="desc">{selectedItem.length}</span>
                  </h3>
                )}
                {selectedItem.passengers && (
                  <h3>
                    Кількість пасажирів: <span className="desc">{selectedItem.passengers}</span>
                  </h3>
                )}
                {(selectedItem.contactPhone || selectedItem.contactName) && (
                  <h3>
                    Контакт:
                    <span className="desc">
                      {selectedItem.contactPhone && (
                        <>
                          <a className="desc" href={`tel:+38${selectedItem.contactPhone.replace(/\D/g, "")}`}>
                            {selectedItem.contactPhone}
                          </a>
                        </>
                      )}
                      {selectedItem.contactName && <span>{selectedItem.contactName}</span>}
                    </span>
                  </h3>
                )}
              </div>
              {selectedItem.yachtRental && selectedItem.yachtRental.length > 0 && (
                <div className="two-fr-detail__prices">
                  <h3>Оренда яхти:</h3>
                  <ul>
                    {selectedItem.yachtRental.map((el, index) => (
                      <li className="desc" key={index}>
                        {el}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="two-fr-detail__paragraph">
                <p className="desc">{selectedItem.description}</p>
              </div>
              {selectedItem.schedule && (
                <div className="two-fr-detail__shorts">
                  <h3>
                    Графік роботи: <span className="desc">{selectedItem.schedule}</span>
                  </h3>
                </div>
              )}
              {selectedItem.legend && (
                <>
                  <h3 className="desc">МІСЦЕВА ЛЕГЕНДА</h3>
                  <p className="desc">{selectedItem.legend}</p>
                </>
              )}
              {selectedItem.routesList && selectedItem.routesList.length > 0 && (
                <div className="two-fr-detail__prices">
                  <h3>Маршрути, якими можна скористатись:</h3>
                  <ul>
                    {selectedItem.routesList.map((route, index) => (
                      <li className="desc" key={index}>
                        {route}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {selectedItem.priceList && selectedItem.priceList.length > 0 && (
                <div className="two-fr-detail__prices">
                  <h3>Вартість:</h3>
                  <ul>
                    {selectedItem.priceList.map((el, index) => (
                      <li className="desc" key={index}>
                        {el}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {selectedItem.website && (
                <div className="two-fr-detail__prices">
                  <h3>
                    Сайт для детальної інформації:
                    <a href={selectedItem.website} target="_blank" rel="noopener noreferrer" className="desc">
                      {selectedItem.website}
                    </a>
                  </h3>
                </div>
              )}
              {selectedItem.email && (
                <div className="two-fr-detail__prices">
                  <h3>
                    Email:
                    <a href={`mailto:${selectedItem.email}`} className="desc">
                      {selectedItem.email}
                    </a>
                  </h3>
                </div>
              )}
              <div className="two-fr-detail__socials">
                {selectedItem.instagramLink && (
                  <div className="two-fr-detail__socials-link">
                    <h3>Instagram: </h3>
                    <a href={selectedItem.instagramLink} target="_blank" rel="noopener noreferrer">
                      <InstaIcon />
                    </a>
                  </div>
                )}
                {selectedItem.facebookLink && (
                  <div className="two-fr-detail__socials-link">
                    <h3>Facebook: </h3>
                    <a href={selectedItem.facebookLink} target="_blank" rel="noopener noreferrer">
                      <FacebookIcon />
                    </a>
                  </div>
                )}
                {selectedItem.tiktokLink && (
                  <div className="two-fr-detail__socials-link">
                    <h3>TikTok: </h3>
                    <a href={selectedItem.tiktokLink} target="_blank" rel="noopener noreferrer">
                      <TiktokIcon />
                    </a>
                  </div>
                )}
              </div>
              {selectedItem.address && (
                <div className="two-fr-detail__shorts">
                  <h3>
                    Адреса: <span className="desc">{selectedItem.address}</span>
                  </h3>
                </div>
              )}
              {selectedItem.mapLink && (
                <div className="two-fr-detail__map">
                  <a href={selectedItem.mapLink} target="_blank" rel="noopener noreferrer">
                    <button className="mapBtn">На мапі</button>
                  </a>
                </div>
              )}
              <div className="two-fr-detail__photos">
                {selectedItem.slides.length > 0 ? (
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={"auto"}
                    autoHeight={true}
                    freeMode={true}
                    grabCursor={true}
                    resistanceRatio={0}
                    navigation={true}
                    autoplay={{
                      delay: 10000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation]}
                    className="two-fr-detail__swiper"
                  >
                    {selectedItem.slides.map((slide, index) => (
                      <SwiperSlide key={index} className="two-fr-detail__slides">
                        <img src={process.env.PUBLIC_URL + slide} alt={`slide-${index}`} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  <p className="desc">Перепрошуємо, наразі немає фотографій</p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default AdventuresDetail;
