import PageTop from "./ui/PageTop";
import PageCard from "./ui/PageCard";
import "../styles/Heaven.scss";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import db from "../firebase";
import { getHeavenTypes, setHeavenTypes } from "store/sessionStorages";
import { fastGetBackgrounds } from "store/loadDataFunctions";

const Heaven = () => {
  const [allHeaven, setAllHeaven] = useState<any>([]);
  const pageName = "heaven";
  const [allBg, setAllBg] = useState<any>(null);
  const [bg, setBG] = useState<any>(
    "https://firebasestorage.googleapis.com/v0/b/shatskiy-45ad2.appspot.com/o/Backgrounds%2Fsunset.webp?alt=media&token=1c062b35-2b11-4648-85aa-911181c751f8"
  );

  useEffect(() => {
    const data = getHeavenTypes();
    if (data) {
      setAllHeaven(data);
    } else {
      const colRef = collection(db, "heaven");
      getDocs(colRef)
        .then((snapshot) => {
          let heavens: any = [];
          snapshot.docs.forEach((doc) => {
            heavens.push({ ...doc.data(), id: doc.id });
          });
          heavens = heavens.sort((a: any, b: any) => a.id - b.id);
          setAllHeaven(heavens);
          setHeavenTypes(heavens);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    loadBackgrounds();
  }, []);

  const loadBackgrounds = async () => {
    const items = await fastGetBackgrounds(setAllBg);
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].page === pageName) {
          setBG(items[i].img);
        }
      }
    }
  };
  return (
    <section className="heaven">
      <div className="heaven__container container">
        <PageTop pageTitle="Рай був тут" imgSrc={bg} imgAlt="heaven image" />
        <div className="heaven__container screen">
          <div className="heaven__text">
            <h4 className="title">Основне</h4>
            <p className="desc">
              Особливо цікавим для відкриття таємниць того далекого минулого є дослідження давньоруського городища на
              східній околиці Шацька. На археологічну карту у 1899 р. його вперше наніс відомий український історик
              Володимир Антонович. За сміливим припущенням молодого шацького археолога Сергія Демедюка, саме поблизу
              Шацька розташовувалося літописне місто Рай - одна з улюблених резиденцій волинського князя Володимира
              Васильковича (? - 10 грудня 1288 р.).
              <br />
              <br />
              Такої ж думки притримується й Олексій Злато-горський, керівник Волинської філії Державного підприємства
              «Науково-дослідний центр «Охо-рона архітектурної спадщини України» Інституту археології Національної
              академії наук України. Виявлена під час обстеження городища кераміка давньоруського часу, аналогів якої на
              Волині поки що не знайдено, та багатство інших знахідок наводять на думку: Рай входив до складу городищ
              Турово-Пінської землі. І у XIII - XIV століттях навіть виступав княжим двором. На користь цієї версії
              працюють, зокрема, рідкісні знахідки - княжі вислі печатки (були) XI - XII століть. Одна з них - з написом
              на реверсі «дін-єсло-во» - належить князю Святополку-Михайлу Ізяславовичу, друга (можливо) - княгині Марії
              (Анастасії), жінці князя Всеволода Ярославича. Під 1158 роком в давньоруському літописі є цікава згадка
              про війну руських князів проти туро-во-пінського князя Юрія Ярославовича. Згадані й міста, що були
              пограбовані: Туров, Пінськ, Рай (виділення - автора) і землі за річкою Припʼяттю.
              <br />
              <br />
              Літописний Рай, таким чином, на переконання волинського дослідника, знаходився саме в Шацьку, на західних
              кордонах Турово-Пінської землі, і належав турово-пінському князеві. Згаданий вище Святополк-Михаїл,
              печатка якого була виявлена на городищі, князював у Турові з 1088 по 1093 роки... Високу археологічну
              цінність урочища «Сад» підтвердили і розкопки 2010 року. Дослідники відкрили кілька готських та
              давньоруських жител ХІ- XII століття, господарські ями, а також знайшли чимало кераміки, виробів із
              заліза: наконечники стріл лука та арбалета, шпори. Цю колекцію поповнив срібний римський динарій другого
              століття нашої ери, відкарбований у часи імператора Тра-яна або Антонія Пія. Але справжньою сенсацією став
              невеликий виріб з рогу, на якому прадавній різьбяр вирізав голову вовка чи дракона. Таким чином усталена у
              «Хроніці» Яна Длугоша дата першої згадки Шацька - 1410 рік, на думку О. Златогорського, може бути
              переглянута у бік «старіння» щонайменше на три сотні літ. Проте нині це поки що лише наукова версія. І
              тому вернемося в XIII століття...
            </p>
          </div>
          <div className="heaven__cards">
            <h4>Переходь за посиланнями для детальної інформації</h4>
            <div className="heaven__list">
              {allHeaven.map((el: any, index: any) => (
                <div className="heaven__card" key={index}>
                  <PageCard
                    cardTitle={el.pageTitle}
                    cardImg={el.pageImg}
                    cardAlt={el.pageAlt}
                    cardName={el.pageName}
                    basePath="/heaven"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Heaven;
