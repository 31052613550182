"use client";
import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ChildPageTop from "./ui/ChildPageTop";
import "../styles/LakesDetail.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import PageCard from "./ui/PageCard";
import { fastGetSecurity } from "store/loadDataFunctions";

const SecurityDetail: React.FC = () => {
  const { cardName } = useParams<{ cardName: string }>();
  const [security, setSecurity] = useState<any>(null);
  const [otherSecurities, setOtherSecurities] = useState<any>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadManufacturers = async () => {
      const data = await fastGetSecurity(setOtherSecurities);
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].pageName === cardName) {
            setSecurity(data[i]);
          }
        }
      }
    };

    loadManufacturers();
  }, [cardName]);

  useEffect(() => {
    setTimeout(() => {
      containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }, [cardName]);

  if (!security) {
    return <div>Інформацію не знайдено</div>;
  }

  return (
    <section className="lake">
      <ChildPageTop
        pageTitle={security.pageName}
        imgSrc={security.pageImg}
        imgAlt={security.pageAlt}
        prevPage={security.motherPage}
        prevPageLink={security.motherPageLink}
      />
      <div className="lake__container screen">
        <div className="lake__detail">
          <div className="lake__info">
            <div className="lake__short">
              <div className="lake__img">
                <img src={process.env.PUBLIC_URL + security.pageImg} alt={security.pageAlt} />
              </div>
              <div className="lake__facts-section">
                <h1 className="title">{security.pageName}</h1>
                <div className="lake__short-facts">
                  <div className="two-fr-detail__shorts lake__facts-list">
                    <h4 className="desc">Номери екстрених служб:</h4>
                    <h3>
                      101 <span> - пожежна охорона</span>
                    </h3>
                    <h3>
                      102 <span> - поліція</span>
                    </h3>
                    <h3>
                      103 <span> - швидка допомога</span>
                    </h3>
                    <h3>
                      104 <span> - аварійна служба газу</span>
                    </h3>
                    <p className="desc">
                      Дзвінки на номери всіх екстрених служб БЕЗКОШТОВНІ і можуть бути здійсненні навіть при негативному
                      балансі на особистому рахунку.
                    </p>
                  </div>
                </div>
                {security.descShort && (
                  <div className="lake__desc">
                    <p className="desc">{security.descShort}</p>
                    {security.descShortList && security.descShortList.length > 0 && (
                      <ul>
                        {security.descShortList.map((el: any, index: any) => (
                          <li className="desc" key={index}>
                            {el}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </div>
            {security.descFull && <p className="desc">{security.descFull}</p>}
            {security.descFullList && security.descFullList.length > 0 && (
              <div className="two-fr-detail__prices">
                {security.descFullTitle && <h3>{security.descFullTitle}</h3>}
                <ul>
                  {security.descFullList.map((el: any, index: any) => (
                    <li className="desc" key={index}>
                      {el}
                    </li>
                  ))}
                </ul>
                <br />
                <br />
              </div>
            )}
            {security.descSecond && <p className="desc">{security.descSecond}</p>}
            {security.descSecondList && security.descSecondList.length > 0 && (
              <div className="two-fr-detail__prices">
                {security.descSecondTitle && <h3>{security.descSecondTitle}</h3>}
                <ul>
                  {security.descSecondList.map((el: any, index: any) => (
                    <li className="desc" key={index}>
                      {el}
                    </li>
                  ))}
                </ul>
                <br />
                <br />
              </div>
            )}
            {security.descProtocolList && security.descProtocolList.length > 0 && (
              <div className="two-fr-detail__prices">
                {security.descProtocolTitle && <h3>{security.descProtocolTitle}</h3>}
                <ul>
                  {security.descProtocolList.map((el: any, index: any) => (
                    <li className="desc" key={index}>
                      {el}
                    </li>
                  ))}
                </ul>
                <br />
                <br />
              </div>
            )}
            {security.descProtocol && <p className="desc">{security.descProtocol}</p>}
          </div>
          {security.slides && (
            <div className="lake__photos">
              <Swiper
                spaceBetween={40}
                autoHeight={true}
                freeMode={true}
                grabCursor={true}
                slidesPerView={1.5}
                resistanceRatio={0}
                loop={true}
                autoplay={{
                  delay: 10000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="lake__swiper-first"
              >
                {security.slides.map((slide: any, index: any) => (
                  <SwiperSlide key={index} className="lake__swiper-first-slides">
                    <img src={process.env.PUBLIC_URL + slide} alt="lake photos" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
        <div className="lake__other-lakes">
          <h1 className="title">До інших сторінок</h1>
          <Swiper
            spaceBetween={40}
            freeMode={true}
            grabCursor={true}
            autoHeight={true}
            slidesPerView={3}
            resistanceRatio={0}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="lake__swiper-other"
          >
            {otherSecurities &&
              otherSecurities.map((otherSecuritie: any, index: any) => (
                <SwiperSlide key={index} className="lake__swiper-other-slides">
                  <PageCard
                    cardTitle={otherSecuritie.pageTitle}
                    cardImg={otherSecuritie.pageImg}
                    cardAlt={otherSecuritie.pageAlt}
                    cardName={otherSecuritie.pageName}
                    basePath="/security"
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default SecurityDetail;
