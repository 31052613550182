import React from 'react'

const InstaIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 24C5.383 24 0 18.617 0 12C0 5.383 5.383 0 12 0C18.617 0 24 5.383 24 12C24 18.617 18.617 24 12 24ZM12 1C5.935 1 1 5.935 1 12C1 18.065 5.935 23 12 23C18.065 23 23 18.065 23 12C23 5.935 18.065 1 12 1Z" fill="#1919DD"/>
        <g clipPath="url(#clip0_1367_2078)">
            <path d="M14.9167 18.4166H9.08337C8.15512 18.4166 7.26488 18.0478 6.6085 17.3915C5.95212 16.7351 5.58337 15.8448 5.58337 14.9166V9.08325C5.58337 8.15499 5.95212 7.26476 6.6085 6.60838C7.26488 5.952 8.15512 5.58325 9.08337 5.58325H14.9167C15.845 5.58325 16.7352 5.952 17.3916 6.60838C18.048 7.26476 18.4167 8.15499 18.4167 9.08325V14.9166C18.4167 15.8448 18.048 16.7351 17.3916 17.3915C16.7352 18.0478 15.845 18.4166 14.9167 18.4166ZM9.08337 6.74992C8.46454 6.74992 7.87104 6.99575 7.43346 7.43334C6.99587 7.87092 6.75004 8.46441 6.75004 9.08325V14.9166C6.75004 15.5354 6.99587 16.1289 7.43346 16.5665C7.87104 17.0041 8.46454 17.2499 9.08337 17.2499H14.9167C15.5355 17.2499 16.129 17.0041 16.5666 16.5665C17.0042 16.1289 17.25 15.5354 17.25 14.9166V9.08325C17.25 8.46441 17.0042 7.87092 16.5666 7.43334C16.129 6.99575 15.5355 6.74992 14.9167 6.74992H9.08337Z" fill="#1919DD"/>
            <path d="M12 15.5C11.3078 15.5 10.6311 15.2947 10.0555 14.9101C9.47993 14.5256 9.03133 13.9789 8.76642 13.3394C8.50152 12.6999 8.4322 11.9961 8.56725 11.3172C8.7023 10.6383 9.03564 10.0146 9.52513 9.52513C10.0146 9.03564 10.6383 8.7023 11.3172 8.56725C11.9961 8.4322 12.6999 8.50152 13.3394 8.76642C13.9789 9.03133 14.5256 9.47993 14.9101 10.0555C15.2947 10.6311 15.5 11.3078 15.5 12C15.5 12.9283 15.1313 13.8185 14.4749 14.4749C13.8185 15.1313 12.9283 15.5 12 15.5ZM12 9.66667C11.5385 9.66667 11.0874 9.80352 10.7037 10.0599C10.32 10.3163 10.0209 10.6807 9.84428 11.1071C9.66768 11.5334 9.62147 12.0026 9.7115 12.4552C9.80153 12.9078 10.0238 13.3236 10.3501 13.6499C10.6764 13.9762 11.0922 14.1985 11.5448 14.2885C11.9974 14.3785 12.4666 14.3323 12.8929 14.1557C13.3193 13.9791 13.6837 13.68 13.9401 13.2963C14.1965 12.9126 14.3333 12.4615 14.3333 12C14.3333 11.3812 14.0875 10.7877 13.6499 10.3501C13.2123 9.9125 12.6188 9.66667 12 9.66667Z" fill="#1919DD"/>
            <path d="M15.5 8.49992C15.8221 8.49992 16.0833 8.23875 16.0833 7.91659C16.0833 7.59442 15.8221 7.33325 15.5 7.33325C15.1778 7.33325 14.9166 7.59442 14.9166 7.91659C14.9166 8.23875 15.1778 8.49992 15.5 8.49992Z" fill="#1919DD"/>
        </g>
        <defs>
            <clipPath id="clip0_1367_2078">
                <rect width="14" height="14" fill="white" transform="translate(5 5)"/>
            </clipPath>
        </defs>
    </svg>
  )
}

export default InstaIcon;