import React from 'react'

const TiktokIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0264 24H11.9736C5.37143 24 0 18.6286 0 12.0264V11.9736C0 5.37142 5.37143 0 11.9736 0H12.0264C18.6286 0 24 5.37142 24 11.9736V12.0264C24 18.6286 18.6286 24 12.0264 24ZM11.9736 0.812375C5.81904 0.812375 0.812375 5.81904 0.812375 11.9736V12.0264C0.812375 18.181 5.81904 23.1876 11.9736 23.1876H12.0264C18.181 23.1876 23.1876 18.181 23.1876 12.0264V11.9736C23.1876 5.81904 18.181 0.812375 12.0264 0.812375H11.9736Z" fill="#1919DD"/>
        <path d="M17.605 8.79478V10.9841C17.2223 10.9468 16.7252 10.8598 16.1752 10.6584C15.4571 10.3952 14.9225 10.0353 14.5724 9.75664V14.1816L14.5634 14.1678C14.5691 14.2556 14.5724 14.3449 14.5724 14.4351C14.5724 16.6326 12.7843 18.4214 10.5861 18.4214C8.38777 18.4214 6.59973 16.6326 6.59973 14.4351C6.59973 12.2376 8.38777 10.448 10.5861 10.448C10.8013 10.448 11.0126 10.465 11.2189 10.4983V12.656C11.0207 12.5853 10.8078 12.5471 10.5861 12.5471C9.5454 12.5471 8.6981 13.3936 8.6981 14.4351C8.6981 15.4766 9.5454 16.3231 10.5861 16.3231C11.6267 16.3231 12.474 15.4757 12.474 14.4351C12.474 14.3961 12.4732 14.3571 12.4708 14.3181V5.71912H14.6585C14.6666 5.90434 14.6739 6.09119 14.6821 6.27641C14.6967 6.64117 14.8267 6.99131 15.0533 7.27808C15.319 7.61522 15.7113 8.00677 16.2621 8.31953C16.778 8.61117 17.2622 8.7371 17.605 8.7964V8.79478Z" fill="#1919DD"/>
    </svg>
  )
}

export default TiktokIcon;