import React, { useEffect, useState } from "react";
import PageTop from "./ui/PageTop";
import "../styles/Partnership.scss";
import PageCard from "./ui/PageCard";
import { fastGetBackgrounds, fastGetPartnerships } from "store/loadDataFunctions";

const Partnership = () => {
  const [partnership, setPartnership] = useState<any>(null);
  const pageName = "partners";
  const [allBg, setAllBg] = useState<any>(null);
  const [bg, setBG] = useState<any>(
    "https://firebasestorage.googleapis.com/v0/b/shatskiy-45ad2.appspot.com/o/Backgrounds%2Fsunset.webp?alt=media&token=1c062b35-2b11-4648-85aa-911181c751f8"
  );

  useEffect(() => {
    const loadPartnerships = async () => {
      const data = await fastGetPartnerships(setPartnership);
      if (data) {
        return;
      }
    };

    loadPartnerships();
    loadBackgrounds();
  }, []);

  const loadBackgrounds = async () => {
    const items = await fastGetBackgrounds(setAllBg);
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].page === pageName) {
          setBG(items[i].img);
        }
      }
    }
  };

  return (
    <section className="partnership">
      <div className="partnership__container container">
        <PageTop pageTitle="Партнерство" imgSrc={bg} imgAlt="partnership image" />
        <div className="partnership__list screen">
          {partnership &&
            partnership.map((el: any, index: any) => (
              <div key={index} className="partnership__card">
                <PageCard
                  cardTitle={el.pageTitle}
                  cardImg={el.pageImg}
                  cardAlt={el.pageAlt}
                  cardName={el.pageName}
                  basePath="/partnership"
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Partnership;
