import React, { useEffect, useState } from "react";
import PageTop from "./ui/PageTop";
import "../styles/Security.scss";
import PageCard from "./ui/PageCard";
import { collection, getDocs } from "firebase/firestore";
import db from "../firebase";
import { getSecurity, setSecurity } from "store/sessionStorages";
import { fastGetBackgrounds } from "store/loadDataFunctions";

const Security = () => {
  const [securityData, setSecurityData] = useState<any>([]);
  const pageName = "security";
  const [allBg, setAllBg] = useState<any>(null);
  const [bg, setBG] = useState<any>(
    "https://firebasestorage.googleapis.com/v0/b/shatskiy-45ad2.appspot.com/o/Backgrounds%2Fsunset.webp?alt=media&token=1c062b35-2b11-4648-85aa-911181c751f8"
  );

  useEffect(() => {
    const data = getSecurity();
    if (data) {
      setSecurityData(data);
    } else {
      const colRef = collection(db, "security");
      getDocs(colRef)
        .then((snapshot) => {
          let security: any = [];
          snapshot.docs.forEach((doc) => {
            security.push({ ...doc.data(), id: doc.id });
          });
          security = security.sort((a: any, b: any) => a.id - b.id);
          setSecurityData(security);
          setSecurity(security);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    loadBackgrounds();
  }, []);

  const loadBackgrounds = async () => {
    const items = await fastGetBackgrounds(setAllBg);
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].page === pageName) {
          setBG(items[i].img);
        }
      }
    }
  };
  return (
    <section className="security">
      <div className="security__container container">
        <PageTop pageTitle="Безпека" imgSrc={bg} imgAlt="security image" />
        <div className="security__list screen">
          {securityData &&
            securityData.map((el: any, index: any) => (
              <div key={index} className="security__card">
                <PageCard
                  cardTitle={el.pageTitle}
                  cardImg={el.pageImg}
                  cardAlt={el.pageAlt}
                  cardName={el.pageName}
                  basePath="/security"
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Security;
