import { useEffect, useState } from "react";
import "../styles/Lakes.scss";
import PageTop from "./ui/PageTop";
import PageCard from "./ui/PageCard";
import { collection, getDocs } from "firebase/firestore";
import db from "../firebase";
import { getLakesData, setLakesData } from "store/sessionStorages";
import ReactPaginate from "react-paginate";
import { fastGetBackgrounds } from "store/loadDataFunctions";

const Lakes = () => {
  const [allLakes, setAllLakes] = useState<any>([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState([]);
  const pageName = "lakes";
  const [allBg, setAllBg] = useState<any>(null);
  const [bg, setBG] = useState<any>(
    "https://firebasestorage.googleapis.com/v0/b/shatskiy-45ad2.appspot.com/o/Backgrounds%2FLakesBG.webp?alt=media&token=54e94c2e-66bb-4164-860f-dc4d9ee8df3f"
  );

  useEffect(() => {
    const data = getLakesData();
    if (data) {
      setAllLakes(data);
    } else {
      const colRef = collection(db, "lakes");
      getDocs(colRef)
        .then((snapshot) => {
          let lakes: any = [];
          snapshot.docs.forEach((doc) => {
            lakes.push({ ...doc.data(), id: doc.id });
          });
          lakes = lakes.sort((a: any, b: any) => a.id - b.id);
          setAllLakes(lakes);
          setLakesData(lakes);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    loadBackgrounds();
  }, []);

  const loadBackgrounds = async () => {
    const items = await fastGetBackgrounds(setAllBg);
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].page === pageName) {
          setBG(items[i].img);
        }
      }
    }
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(allLakes.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(allLakes.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, allLakes]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % allLakes.length;
    setItemOffset(newOffset);
  };

  return (
    <section className="lakes">
      <div className="lakes__container">
        <PageTop pageTitle="Природа 30 озер" imgSrc={bg} imgAlt="Природа 30 озер" />
        <div className="lakes__list screen">
          {currentItems.map((el: any, index: any) => (
            <div key={index} className="lakes__card">
              <PageCard cardTitle={el.title} cardImg={el.img} cardAlt={el.alt} cardName={el.name} basePath="/lakes" />
            </div>
          ))}
        </div>
        <ReactPaginate
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel=""
          pageClassName="GelleryPhotosPaginationButton"
          pageLinkClassName="GelleryPhotosPaginationLink"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="GelleryPhotosPagination"
          activeClassName="activePag"
          renderOnZeroPageCount={null}
        />
      </div>
    </section>
  );
};

export default Lakes;
