import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ChildPageTop from "./ui/ChildPageTop";
import "../styles/LakesDetail.scss";
import { getPartnership } from "store/sessionStorages";
import { collection, getDocs } from "firebase/firestore";
import db from "../firebase";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const PartnershipDetail: React.FC = () => {
  const { cardName } = useParams<{ cardName: string }>();
  const [partnership, setPartnership] = useState<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadPartnerships = async () => {
      const data = getPartnership();
      console.log(data);
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].pageName === cardName) {
            setPartnership(data[i]);
          }
        }
      } else {
        const colRef = collection(db, "partnership");
        getDocs(colRef)
          .then((snapshot) => {
            let allPartnership: any = [];
            snapshot.docs.forEach((doc) => {
              allPartnership.push({ ...doc.data() });
              if (doc.data().pageName === cardName) {
                setPartnership(doc.data());
              }
            });
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    };

    loadPartnerships();
  }, [cardName]);

  useEffect(() => {
    setTimeout(() => {
      containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }, [cardName]);

  if (!partnership) {
    return <div>Інформацію не знайдено</div>;
  }

  return (
    <section className="partnership">
      <ChildPageTop
        pageTitle={partnership.pageName}
        imgSrc={partnership.pageImg}
        imgAlt={partnership.pageAlt}
        prevPage={partnership.motherPage}
        prevPageLink={partnership.motherPageLink}
      />
      <div className="lake__container screen">
        <div className="lake__detail">
          <div className="lake__info">
            <div className="lake__short">
              <div className="lake__img">
                <img src={process.env.PUBLIC_URL + partnership.pageImg} alt={partnership.pageAlt} />
              </div>
              <div className="lake__facts-section">
                <h1 className="title">{partnership.pageName}</h1>
                {partnership.descShort && (
                  <div className="lake__desc">
                    <p className="desc">{partnership.descShort}</p>
                  </div>
                )}

                {partnership.downloadDocumentTitle1 && (
                  <div className="lake__document">
                    <h4 className="desc">{partnership.downloadDocumentTitle1}</h4>
                    <a href={process.env.PUBLIC_URL + partnership.downloadDocument1} className="mapBtn" download>
                      Завантажити Рішення
                    </a>
                    <a href={process.env.PUBLIC_URL + partnership.downloadDocument2} className="mapBtn" download>
                      Завантажити Положення
                    </a>
                  </div>
                )}
              </div>
            </div>
            <p className="desc">{partnership.descFull}</p>
            {partnership.descFullList && partnership.descFullList.length > 0 && (
              <div className="two-fr-detail__prices">
                <ul>
                  {partnership.descFullList.map((el: any, index: any) => (
                    <li className="desc" key={index}>
                      {el}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {partnership.documentLinkTitle2 && (
              <div className="lake__document">
                <h4 className="desc">{partnership.documentLinkTitle2}</h4>
                <div className="lake__map">
                  <a href={partnership.googleLink} target="_blank" rel="noopener noreferrer">
                    <button className="mapBtn">Переглянути</button>
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className="lake__photos">
            <Swiper
              spaceBetween={40}
              autoHeight={true}
              freeMode={true}
              grabCursor={true}
              slidesPerView={1.5}
              resistanceRatio={0}
              loop={true}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="lake__swiper-first"
            >
              {partnership.slides?.map((slide: any, index: any) => (
                <SwiperSlide key={index} className="lake__swiper-first-slides">
                  <img src={slide} alt="lake photos" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnershipDetail;
