import React, { useEffect, useState } from "react";
import PageTop from "./ui/PageTop";
import "../styles/Adventures.scss";
import PageCard from "./ui/PageCard";
import { collection, getDocs } from "firebase/firestore";
import db from "../firebase";
import { getAdventures, setAdventures } from "store/sessionStorages";
import { fastGetBackgrounds } from "store/loadDataFunctions";

const Adventures = () => {
  const [allAdventures, setAllAdventures] = useState<any>([]);
  const pageName = "adventures";
  const [allBg, setAllBg] = useState<any>(null);
  const [bg, setBG] = useState<any>(
    "https://firebasestorage.googleapis.com/v0/b/shatskiy-45ad2.appspot.com/o/Backgrounds%2FAdventuresBG.webp?alt=media&token=a1a48523-4b9a-47f4-aba0-dd89f2814143"
  );

  useEffect(() => {
    const data = getAdventures();
    if (data) {
      setAllAdventures(data);
    } else {
      const colRef = collection(db, "adventures");
      getDocs(colRef)
        .then((snapshot) => {
          let adventures: any = [];
          snapshot.docs.forEach((doc) => {
            adventures.push({ ...doc.data(), id: doc.id });
          });
          adventures = adventures.sort((a: any, b: any) => a.id - b.id);
          setAllAdventures(adventures);
          setAdventures(adventures);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    loadBackgrounds();
  }, []);

  const loadBackgrounds = async () => {
    const items = await fastGetBackgrounds(setAllBg);
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].page === pageName) {
          setBG(items[i].img);
        }
      }
    }
  };
  return (
    <section className="adventures">
      <div className="adventures__container container">
        <PageTop pageTitle="Місця і пригоди" imgSrc={bg} imgAlt="adventures image" />
        <div className="adventures__list screen">
          {allAdventures.map((el: any, index: any) => (
            <div key={index} className="adventures__card">
              <PageCard
                cardTitle={el.pageTitle}
                cardImg={el.pageImg}
                cardAlt={el.pageAlt}
                cardName={el.pageName}
                basePath="/adventures"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Adventures;
