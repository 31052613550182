import ArrowToRightIcon from "assets/ArrowToRightIcon";
import React from "react";
import "../../styles/index.scss";
import { Link } from "react-router-dom";

interface MyPageTop {
  pageTitle: string;
  imgSrc: string;
  imgAlt: string;
}

const PageTop: React.FC<MyPageTop> = ({ pageTitle, imgSrc, imgAlt }) => {
  return (
    <div className="page__top">
      <img src={process.env.PUBLIC_URL + imgSrc} alt={imgAlt} />
      <div className="page__title">
        <h2>{pageTitle}</h2>
        <div className="page__breadcrumbs">
          <Link to={"/"} className="page__link__home">
            Головна
          </Link>
          <ArrowToRightIcon />
          <h4>{pageTitle}</h4>
        </div>
      </div>
    </div>
  );
};

export default PageTop;
