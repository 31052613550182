"use client";
import ArrowToRightIcon from "assets/ArrowToRightIcon";
import "../styles/LakesDetail.scss";
import React, { useRef, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import PageCard from "./ui/PageCard";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { fastGetNews } from "store/loadDataFunctions";

const NewsDetail: React.FC = () => {
  const { cardName } = useParams<{ cardName: string }>();
  const [news, setNews] = useState<any>(null);
  const [otherNews, setOtherNews] = useState<any>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadManufacturers = async () => {
      const data = await fastGetNews(setOtherNews);
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].name === cardName) {
            setNews(data[i]);
          }
        }
      }
    };

    loadManufacturers();
  }, [cardName]);

  useEffect(() => {
    setTimeout(() => {
      containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }, [cardName]);

  if (!news) {
    return <div>Інформацію не знайдено</div>;
  }

  const hasNewsFacts = news.date || news.area || news.duration;

  return (
    <section className="lake" ref={containerRef}>
      <div className="lake-breadcrumbs">
        <div className="lake-breadcrumbs__links screen">
          <Link to={"/"}>Головна</Link>
          <ArrowToRightIcon />
          <h4>{news.name}</h4>
        </div>
      </div>
      <div className="lake__container screen">
        <div className="lake__details">
          <div className="lake__info">
            <div className="lake__short">
              <div className="lake__img">
                <img src={process.env.PUBLIC_URL + news.img} alt={news.alt} />
              </div>
              <div className="lake__facts-section">
                <h1 className="title">{cardName}</h1>
                {hasNewsFacts && (
                  <div className="lake__short-facts">
                    <h4>Основне:</h4>
                    <div className="lake__facts-list">
                      <div className="lake__facts-list-fact">
                        {news.date && <h3>Дата публікації</h3>}
                        {news.area && <h3>Місце</h3>}
                        {news.duration && <h3>Тривалість</h3>}
                      </div>
                      <div className="lake__facts-list-numbs">
                        {news.date && <p>{news.date}</p>}
                        {news.area && <p>{news.area}</p>}
                        {news.duration && <p>{news.duration}</p>}
                      </div>
                    </div>
                  </div>
                )}
                <div className="lake__desc">
                  <p className="desc">{news.descShort}</p>
                </div>
                {news.maplink && (
                  <div className="lake__map">
                    <a href={news.maplink} target="_blank" rel="noopener noreferrer">
                      <button className="mapBtn">На мапі</button>
                    </a>
                  </div>
                )}
              </div>
            </div>
            <p className="desc">{news.descFull}</p>
            {/* <div className="video-section">
              <video controls width="100%">
                <source src={process.env.PUBLIC_URL + "/video/2.mov"} type="video/mp4" />
                Ваш браузер не підтримує відтворення відео.
              </video>
            </div> */}
            {news.video && (
              <div className="video-section">
                <video controls width="100%">
                  <source src={process.env.PUBLIC_URL + news.video} type="video/mp4" />
                  Ваш браузер не підтримує відтворення відео.
                </video>
              </div>
            )}
          </div>
          <div className="lake__photos">
            <Swiper
              spaceBetween={40}
              autoHeight={true}
              freeMode={true}
              grabCursor={true}
              slidesPerView={1.5}
              resistanceRatio={0}
              loop={true}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="lake__swiper-first"
            >
              {news &&
                news.slides.map((slide: any, index: any) => (
                  <SwiperSlide key={index} className="lake__swiper-first-slides">
                    <img src={process.env.PUBLIC_URL + slide} alt="photos" />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
        <div className="lake__other-lakes">
          <h1 className="title">До інших новин</h1>
          <Swiper
            spaceBetween={40}
            freeMode={true}
            grabCursor={true}
            slidesPerView={3}
            resistanceRatio={0}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="lake__swiper-other"
          >
            {otherNews &&
              otherNews.map((otherNew: any, index: any) => (
                <SwiperSlide key={index} className="lake__swiper-other-slides">
                  <PageCard
                    cardTitle={otherNew.title}
                    cardImg={otherNew.img}
                    cardAlt={otherNew.alt}
                    cardName={otherNew.name}
                    basePath="/news"
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default NewsDetail;
