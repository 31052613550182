import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Footer.scss";
import FileIcon from "assets/FileIcon";
import InstaIcon from "assets/InstaIcon";
import FacebookIcon from "assets/FacebookIcon";
import TelegramIcon from "assets/TelegramIcon";
import { message } from "antd";
import { sendFile, sendMessage } from "../api/telegram";

const messages = [
  {
    messageSuccess: "Ваше повідомлення успішно надіслано!",
  },
  {
    messageError: "Заповніть усі поля!",
  },
]

const Footer = () => {
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();
  const [logo, setLogo] = useState(process.env.PUBLIC_URL + "/logo.webp");

  const messageSuccess = () => {
    messageApi.open({
      type: "success",
      content: messages[0].messageSuccess,
      className: "Message",
    });
  };

  const messageError = () => {
    messageApi.open({
      type: "error",
      content: messages[1].messageError,
      className: "Message",
    });
  };

  const [selectedFileName, setSelectedFileName] = useState<string | null>("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    text: "",
    file: null,
  });

  const handleChange = (e: any) => {
    const { name, value, files } = e.target;
    if (name === "file" && files.length > 0) {
      setFormValues({
        ...formValues,
        [name]: files[0],
      });
      setSelectedFileName(files[0].name);
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      if (formValues.name && formValues.phone && formValues.text) {
        console.log(formValues.phone);
        const message = `Нове повідомлення: %0A- Ім'я: ${formValues.name} %0A- Номер телефону: ${formValues.phone} %0A- Текст: ${formValues.text}`;

        await sendMessage(message);
        messageSuccess();
      } else {
        messageError();
      }

      if (formValues.file) {
        await sendFile(formValues.file);
      }
    } catch (e) {
      console.log("Error");
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 992) {
            setLogo(process.env.PUBLIC_URL + "/logo_long.webp");
        } else {
            setLogo(process.env.PUBLIC_URL + "/logo.webp");
        }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
        window.removeEventListener("resize", handleResize);
    };
}, []);

  function CurrentYear() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    return <span>{currentYear}</span>;
  }

  return (
    <section className='footer'>
      {contextHolder}
      <div className="footer-container screen">
        <div className="footer-top">
          <div className="footer-top__bloc">
            <div className="footer-top__logo">
              <Link to={"/"}>
                <img src={logo} alt="Logo" />
              </Link>
              <div className="footer-top__text">
                <a 
                  href="https://maps.app.goo.gl/6NiKxCotaXDukziK6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>Волинська область, Ковельський район<br/>УКРАЇНА</p>
                </a>
              </div>
              <a className="footer-top__mail" href="mailto:otg@shsrada.gov.ua">otg@shsrada.gov.ua</a>
              <div className="footer-top__socials">
                <a href="https://www.instagram.com/shatsk.region?igsh=MmI0aHd0b3hreDlh">
                  <InstaIcon />
                </a>
                <a href="https://www.facebook.com/shatsk.region">
                  <FacebookIcon />
                </a>
                <a href="https://t.me/+M4mqVWdZT4I5Yjliotg@shsrada.gov.ua">
                  <TelegramIcon />
                </a>
              </div>
            </div>
            <div className="footer-top__links">
              <div className="footer-top__title">Посилання</div>
              <div className="footer-top__links-section">
                <div className="footer-top__links-section__first">
                  <Link to={"/"} className={location.pathname === '/' ? 'active' : ''}>Головна</Link>
                  <Link to={"/lakes"} className={location.pathname === '/lakes' ? 'active' : ''}>Природа 30 озер</Link>
                  <Link to={"/heaven"} className={location.pathname === '/heaven' ? 'active' : ''}>Рай був тут</Link>
                  <Link to={"/adventures"} className={location.pathname === '/adventures' ? 'active' : ''}>Місця і пригоди</Link>
                </div>
                <div className="footer-top__links-section__second">
                  <Link to={"/manufacturers"} className={location.pathname === '/manufacturers' ? 'active' : ''}>Крафтові виробники</Link>
                  <Link to={"/partnership"} className={location.pathname === '/partnership' ? 'active' : ''}>Партнерство</Link>
                  <Link to={"/security"} className={location.pathname === '/security' ? 'active' : ''}>Безпека</Link>
                  <Link to={"/places"} className={location.pathname === '/places' ? 'active' : ''}>Унікальні місця для селфі</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top__forms">
            <div className="footer-top__title">Розмістити оголошення про діяльність в Шацькій громаді</div>
            <div className="footer-top__inputs">
              <form onSubmit={handleSubmit}>
                <input 
                  id="name"
                  name="name"
                  type="text"
                  placeholder="ПІБ"
                  value={formValues.name}
                  onChange={handleChange}
                />
                <input 
                  id="phone"
                  name="phone"
                  type="tel"
                  placeholder="Контактний номер телефону"
                  value={formValues.phone}
                  onChange={handleChange}
                />
                <input 
                  id="projectDescription"
                  name="text"
                  type="text"
                  placeholder="Ваша діяльність та посилання на соц. мережі"
                  value={formValues.text}
                  onChange={handleChange}
                />
                <div className="footer-top__inputs__buttons">
                  <div className="footer-top__inputs__attach">
                    <input 
                      id="file"
                      name="file"
                      type="file"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      accept=".pdf, .svg, .webp, .png, .jpg, .jpeg, .HEIC"
                      onChange={handleChange}
                    />
                    <button type="button" onClick={handleFileUpload}>
                      <FileIcon />
                    </button>
                    <h4>{selectedFileName ? selectedFileName : "Прикріпити фото"}</h4>
                  </div>
                  <button type="submit">
                    Надіслати
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <span className="copyright">
            <CurrentYear /> © Шацький край. Всі права захищені
          </span>
          <span className="copyright">
            Developed by
            <a href="https://abc-digital.com/"> ABC Digital</a>
          </span>
        </div>
      </div>
    </section>
  )
}

export default Footer;