"use client";
import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ChildPageTop from "./ui/ChildPageTop";
import "../styles/LakesDetail.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import InstaIcon from "assets/InstaIcon";
import FacebookIcon from "assets/FacebookIcon";
import PageCard from "./ui/PageCard";
import { fastGetManufacturers } from "store/loadDataFunctions";

const ManufacturersDetail: React.FC = () => {
  const { cardName } = useParams<{ cardName: string }>();
  const [manufacturer, setManufacturer] = useState<any>(null);
  const [otherManufacturers, setOtherManufacturers] = useState<any>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadManufacturers = async () => {
      const data = await fastGetManufacturers(setOtherManufacturers);
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].pageName === cardName) {
            setManufacturer(data[i]);
          }
        }
      }
    };

    loadManufacturers();
  }, [cardName]);

  useEffect(() => {
    setTimeout(() => {
      containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }, [cardName]);

  if (!manufacturer) {
    return <div>Інформацію не знайдено</div>;
  }

  const hasManufacturerContacts =
    manufacturer.website ||
    manufacturer.contactPhone ||
    manufacturer.contactName ||
    manufacturer.facebookLink ||
    manufacturer.instagramLink ||
    manufacturer.mapLink;

  return (
    <section className="lake">
      <ChildPageTop
        pageTitle={manufacturer.pageName}
        imgSrc={manufacturer.pageImg}
        imgAlt={manufacturer.pageAlt}
        prevPage={manufacturer.motherPage}
        prevPageLink={manufacturer.motherPageLink}
      />
      <div className="lake__container screen">
        <div className="lake__detail">
          <div className="lake__info">
            <div className="lake__short">
              <div className="lake__img">
                <img src={process.env.PUBLIC_URL + manufacturer.pageImg} alt={manufacturer.pageAlt} />
              </div>
              <div className="lake__facts-section">
                <h1 className="title">{manufacturer.pageName}</h1>
                <div className="lake__short-facts">
                  {hasManufacturerContacts && (
                    <div className="two-fr-detail__shorts lake__facts-list">
                      {(manufacturer.contactPhone || manufacturer.contactName) && (
                        <h3>
                          Контакт:
                          <span className="desc">
                            {manufacturer.contactPhone && (
                              <>
                                <a className="desc" href={`tel:+38${manufacturer.contactPhone.replace(/\D/g, "")}`}>
                                  {manufacturer.contactPhone}
                                </a>
                              </>
                            )}
                            {manufacturer.contactName && <span>{manufacturer.contactName}</span>}
                          </span>
                        </h3>
                      )}
                      {manufacturer.instagramLink && (
                        <div className="two-fr-detail__socials-link">
                          <h3>Instagram: </h3>
                          <a href={manufacturer.instagramLink} target="_blank" rel="noopener noreferrer">
                            <InstaIcon />
                          </a>
                        </div>
                      )}
                      {manufacturer.facebookLink && (
                        <div className="two-fr-detail__socials-link">
                          <h3>Facebook: </h3>
                          <a href={manufacturer.facebookLink} target="_blank" rel="noopener noreferrer">
                            <FacebookIcon />
                          </a>
                        </div>
                      )}
                      {manufacturer.website && (
                        <div className="two-fr-detail__prices">
                          <h3>
                            Сайт для детальної інформації:
                            <a href={manufacturer.website} target="_blank" rel="noopener noreferrer" className="desc">
                              {manufacturer.website}
                            </a>
                          </h3>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="lake__desc">
                  <p className="desc">{manufacturer.descShort}</p>
                </div>
                {manufacturer.mapLink && (
                  <div className="lake__map">
                    <a href={manufacturer.mapLink} target="_blank" rel="noopener noreferrer">
                      <button className="mapBtn">На мапі</button>
                    </a>
                  </div>
                )}
              </div>
            </div>
            <p className="desc">
              {manufacturer.descFull}
              <br />
              <br />
              <br />
            </p>
            {manufacturer.listContent && manufacturer.listContent.length > 0 && (
              <div className="two-fr-detail__prices">
                <h3>{manufacturer.listTitle}</h3>
                <ul>
                  {manufacturer.listContent.map((el: any, index: any) => (
                    <li className="desc" key={index}>
                      {el}
                    </li>
                  ))}
                </ul>
                <br />
                <br />
              </div>
            )}
          </div>
          <div className="lake__photos">
            <Swiper
              spaceBetween={40}
              autoHeight={true}
              freeMode={true}
              grabCursor={true}
              slidesPerView={1.5}
              resistanceRatio={0}
              loop={true}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="lake__swiper-first"
            >
              {manufacturer.slides.map((slide: any, index: any) => (
                <SwiperSlide key={index} className="lake__swiper-first-slides">
                  <img src={process.env.PUBLIC_URL + slide} alt="lake photos" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="lake__other-lakes">
          <h1 className="title">Інші виробники</h1>
          <Swiper
            spaceBetween={40}
            freeMode={true}
            grabCursor={true}
            slidesPerView={3}
            resistanceRatio={0}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="lake__swiper-other"
          >
            {otherManufacturers &&
              otherManufacturers.map((otherManufacturer: any, index: any) => (
                <SwiperSlide key={index} className="lake__swiper-other-slides">
                  <PageCard
                    cardTitle={otherManufacturer.pageTitle}
                    cardImg={otherManufacturer.pageImg}
                    cardAlt={otherManufacturer.pageAlt}
                    cardName={otherManufacturer.pageName}
                    basePath="/manufacturers"
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default ManufacturersDetail;
