import React from "react";
import "../../styles/index.scss";
import { Link } from "react-router-dom";

interface MyPageCard {
  cardTitle: string;
  cardImg: string;
  cardAlt: string;
  cardName: string;
  basePath: string;
}

const PageCard: React.FC<MyPageCard> = ({ cardTitle, cardImg, cardAlt, cardName, basePath }) => {
  const fullPath = `${basePath.replace(/\/+$/, "")}/${cardName.replace(/^\/+/, "")}`;

  return (
    <div className="pageCard">
      <div className="pageCard__img">
        <img src={process.env.PUBLIC_URL + cardImg} alt={cardAlt} />
      </div>
      <div className="pageCard__text">
        <p>{cardTitle}</p>
        <h2>{cardName}</h2>
      </div>
      <div className="pageCard__link">
        <div className="pageCard__btn">
          <Link to={fullPath} state={{ cardTitle, cardImg, cardAlt, cardName }}>
            <button className="cardBtn">Детальніше</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageCard;
